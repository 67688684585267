/**
 * This work is licensed under the Creative Commons Attribution-Share Alike 3.0
 * United States License. To view a copy of this license,
 * visit http://creativecommons.org/licenses/by-sa/3.0/us/ or send a letter
 * to Creative Commons, 171 Second Street, Suite 300, San Francisco, California, 94105, USA.
 *
 * Modified by: Jill Elaine
 * Email: jillelaine01@gmail.com
 * Modified to work with Modaal by: Eleanor O'Neill
 * Email: eleanor.oneill@cybergrants.com
 *
 * Configurable idle (no activity) timer and logout redirect for jQuery.
 * Works across multiple windows and tabs from the same domain.
 *
 * Dependencies: JQuery v1.7+,
 *               store.modern.min.js from https://github.com/marcuswestin/store.js - v2.0.12+
 *               Modaal from https://github.com/humaan/Modaal - v0.3.1+
 *
 * version 1.0.11
 **/

(function ($) {
  $.fn.idleTimeout = function (userRuntimeConfig) {
    var thisStore;
    var dialogDiv;
    var origTitle = document.title; // save original browser title

    if (userRuntimeConfig.idleTimeLimit) {
      userRuntimeConfig.idleTimeLimit =
        (userRuntimeConfig.idleTimeLimit * 60) - 30;
    }

    /* ----------- Public Configuration Variables ----------- */
    var defaultConfig = {
      idleNamespace: 'internal',   // Used to namespace the store object
      redirectUrl: '/logout',      // redirect to this url on logout. Set to "redirectUrl: false" to disable redirect
      // idle settings
      idleTimeLimit: 60 * 30,      // 'No activity' time limit in seconds. 1200 = 20 Minutes --- NOTE: this value is not in use. The userRuntimeConfig.idleTimeLimit value from above takes precendence over this value.
      idleCheckHeartbeat: 5,       // Frequency to check for idle timeouts in seconds
      // optional custom callback to perform before logout
      customCallback: false,       // set to false for no customCallback
      // configure which activity events to detect
      activityEvents: 'click keypress scroll wheel mousewheel mousemove orientationchange', // separate each event with a space
      // warning dialog box configuration
      enableDialog: true,           // set to false for logout without warning dialog
      dialogClass: 'modal',
      dialogDisplayLimit: 30,       // Time to display the warning dialog before logout (and optional callback) in seconds. 180 = 3 Minutes
      dialogIdentifier: 'idleConfirm',
      dialogTitle: 'Session Expiration Warning', // also displays on browser title bar
      dialogText: 'Because you have been inactive, your session is about to expire.',
      dialogTimeRemainingText: 'Time remaining',
      dialogStayLoggedInButtonText: 'Continue',
      dialogStayLoggedInButtonClass: 'formButton button button--primary', // CG-19058 design review included formButton class
      dialogLogOutNowButtonText: 'Log Out Now',
      dialogLogOutNowButtonClass: 'formButton button secondaryAction', // CG-19058 needs formButton class
      // error message if includes/javascript/store.js not present
      errorAlertMessage: 'Please disable "Private Mode", or upgrade to a modern browser. Or perhaps a dependent file missing.',
      // server-side session keep-alive timer
      sessionKeepAliveTimer: 300, // ping the server at this interval in seconds. 600 = 10 Minutes. Set to false to disable pings
      sessionKeepAliveUrl: `https://${
          window.location.host + cgEnvGetApiPath()
        }session_utl.check_in?x_ts=${$.now()}` // set URL to ping - does not apply if sessionKeepAliveTimer: false
    },

    /* ----------- Private Variables ----------- */
    currentConfig = $.extend(defaultConfig, userRuntimeConfig), // merge default and user runtime configuration
    storeConfiguration, // public function support, store private configuration variables
    activityDetector,
    startKeepSessionAlive, stopKeepSessionAlive, keepSession, keepAlivePing, // session keep alive
    idleTimer, remainingTimer, checkIdleTimeout, checkIdleTimeoutLoop, startIdleTimer, stopIdleTimer, // idle timer
    openWarningDialog, dialogTimer, checkDialogTimeout, startDialogTimer, stopDialogTimer,
    isDialogOpen, destroyWarningDialog, countdownDisplay, // warning dialog
    logoutUser,
    checkForIframes, includeIframes, attachEventIframe; // iframes

    if (!$('.' + currentConfig.dialogIdentifier).length) {
      $('body').append('<div class="' + currentConfig.dialogIdentifier + '"></div>');
      dialogDiv = $('.' + currentConfig.dialogIdentifier);
      // console.log('have a dialog dom element?', dialogDiv.length);
    }

    /* ----------- Public Functions ----------- */

    /* trigger a manual user logout
     * use this code snippet on your site's Logout button:
     * $.fn.idleTimeout().logout(); */
    this.logout = function () {
      thisStore.set('idleTimerLoggedOut', true);
    };

    /* trigger a recheck for iframes
     * use this code snippet after an iframe is inserted into the document:
     * $.fn.idleTimeout().iframeRecheck() */
    this.iframeRecheck = function () {
      checkForIframes();
    };

    /* ----------- Private Functions ----------- */

    /* ----------- PUBLIC FUNCTION SUPPORT --------------
     * WORK AROUND - save currentConfig.activityEvents value to 'store.js'
     * variable for use in function: attachEventIframe
     */
    storeConfiguration = function () {
      thisStore.set('activityEvents', currentConfig.activityEvents);
    };

    //----------- KEEP SESSION ALIVE FUNCTIONS --------------//
    startKeepSessionAlive = function () {
      keepSession = function () {
        $.get(currentConfig.sessionKeepAliveUrl);
        startKeepSessionAlive();
      };
      keepAlivePing = setTimeout(keepSession, (currentConfig.sessionKeepAliveTimer * 1000));
    };

    stopKeepSessionAlive = function () {
      clearTimeout(keepAlivePing);
    };

    //----------- ACTIVITY DETECTION FUNCTION --------------//
    activityDetector = function () {
      $('body').on(currentConfig.activityEvents, function () {
        if (!currentConfig.enableDialog || (currentConfig.enableDialog && isDialogOpen() !== true)) {
          startIdleTimer();
        }
      });
    };

    //----------- IDLE TIMER FUNCTIONS --------------//
    checkIdleTimeout = function () {
      var timeIdleTimeout = (thisStore.get('idleTimerLastActivity') + (currentConfig.idleTimeLimit * 1000));

      if ($.now() > timeIdleTimeout) {
        if (!currentConfig.enableDialog) { // warning dialog is disabled
          logoutUser(); // immediately log out user when user is idle for idleTimeLimit
        } else if (currentConfig.enableDialog && isDialogOpen() !== true) {
          openWarningDialog();
          startDialogTimer(); // start timing the warning dialog
        }
      } else if (thisStore.get('idleTimerLoggedOut') === true) { //a 'manual' user logout?
        logoutUser();
      } else {
        if (currentConfig.enableDialog && isDialogOpen() === true) {
          destroyWarningDialog();
          stopDialogTimer();
        }
      }
    };

    startIdleTimer = function () {
      stopIdleTimer();
      thisStore.set('idleTimerLastActivity', $.now());
      checkIdleTimeoutLoop();
    };

    checkIdleTimeoutLoop = function () {
      checkIdleTimeout();
      idleTimer = setTimeout(checkIdleTimeoutLoop, (currentConfig.idleCheckHeartbeat * 1000));
    };

    stopIdleTimer = function () {
      clearTimeout(idleTimer);
    };

    //----------- WARNING DIALOG FUNCTIONS --------------//
    openWarningDialog = function () {
      if (isDialogOpen()) return;
      var dialogContent = "<div id='modaalSessionConfirm'>" +
      "<p id='session-confirm-dialogtext'>" + currentConfig.dialogText + "</p>" +
      // removed per Scans CG-19058
      // "<p id='session-confirm-timeremainingtext' style='display:inline'>" + currentConfig.dialogTimeRemainingText +
      // ": <div style='display:inline' id='countdownDisplay'></div>" +
      "</p></div>";

      dialogDiv.modaal({
        type: 'confirm',
        custom_class: currentConfig.dialogClass + ' modaalSessionConfirm e2-default-modaal',
        confirm_title: currentConfig.dialogTitle,
        overlay_opacity: 0.6,
        width: 400,
        after_open: function() {
          $('.modaal-content.modaal-focus').removeAttr('aria-label');
          $('.modaal-content.modaal-focus').attr('aria-describedby', 'session-confirm-dialogtext' );
          $('.modaal-content-container').attr('role', 'document');
          $('.modaal-ok')
            .addClass(currentConfig.dialogStayLoggedInButtonClass)
            .attr('aria-label', currentConfig.dialogStayLoggedInButtonText)
            .on('click', function() {
              fetch(currentConfig.sessionKeepAliveUrl)
                .then((response) => {
                  if (!response.ok) {
                    throw new Error(
                      'Network response was not ok ' + response.statusText,
                    );
                  }
                  return response.json();
                })
                .then((data) => {
                  console.log(data);
                })
                .catch((error) => {
                  console.error(
                    'There was a problem with the fetch operation:',
                    error,
                  );
                });
            });
          $('.modaal-cancel')
            .addClass(currentConfig.dialogLogOutNowButtonClass)
            .attr('aria-label', currentConfig.dialogLogOutNowButtonText);
        },
        confirm_content: dialogContent + '&nbsp;', // this trailing space must remain for NVDA.
        confirm_button_text: currentConfig.dialogStayLoggedInButtonText,
        confirm_cancel_button_text: currentConfig.dialogLogOutNowButtonText,
        close_aria_label: currentConfig.dialogText.replace(/"/gi, ''), // translation string has quotes in it, which breaks the aria-label text supplied by modaal
        confirm_callback: function() {
          destroyWarningDialog();
          stopDialogTimer();
          startIdleTimer();
        },
        confirm_cancel_callback: function() {
          logoutUser();
        }
      });
      dialogDiv.trigger('click'); // open the modaal

      countdownDisplay();
      document.title = currentConfig.dialogTitle;
      if (currentConfig.sessionKeepAliveTimer) {
        stopKeepSessionAlive();
      }
    };

    checkDialogTimeout = function () {
      var timeDialogTimeout = (thisStore.get('idleTimerLastActivity') + (currentConfig.idleTimeLimit * 1000) + (currentConfig.dialogDisplayLimit * 1000));

      if (($.now() > timeDialogTimeout) || (thisStore.get('idleTimerLoggedOut') === true)) {
        logoutUser();
      }
    };

    startDialogTimer = function () {
      dialogTimer = setInterval(checkDialogTimeout, (currentConfig.idleCheckHeartbeat * 1000));
    };

    stopDialogTimer = function () {
      clearInterval(dialogTimer);
      clearInterval(remainingTimer);
    };

    isDialogOpen = function () {
      return $(".modaalSessionConfirm").is(":visible") ? true : false;
    };

    destroyWarningDialog = function () {
      dialogDiv.modaal('close');
      document.title = origTitle;
      if (currentConfig.sessionKeepAliveTimer) {
        startKeepSessionAlive();
      }
    };

    countdownDisplay = function () {
      var dialogDisplaySeconds = currentConfig.dialogDisplayLimit,
          mins,
          secs;

      remainingTimer = setInterval(function () {
        mins = Math.floor(dialogDisplaySeconds / 60); // minutes
        secs = mins > 0 ? dialogDisplaySeconds - (mins * 60) : dialogDisplaySeconds; // seconds
        if (mins === 0 && secs === 0) {
           $('#countdownDisplay').html('');
        } else {
          if (mins < 10) { mins = '0' + mins; }
          if (secs < 10) { secs = '0' + secs; }
          $('#countdownDisplay').html(mins + ':' + secs);
          dialogDisplaySeconds--;
          if (dialogDisplaySeconds <= 0) dialogDisplaySeconds = 0;
        }
      }, 1000);
    };

    //----------- LOGOUT USER FUNCTION --------------//
    logoutUser = function () {
      thisStore.set('idleTimerLoggedOut', true);

      if (currentConfig.sessionKeepAliveTimer) {
        stopKeepSessionAlive();
      }
      if (currentConfig.customCallback) {
        currentConfig.customCallback();
      }
      if (currentConfig.redirectUrl) {
        window.location.href = currentConfig.redirectUrl;
      }
    };

    //----------- IFRAME FUNCTIONS --------------//
    // triggered when a dialog is opened, recheck for iframes
    $("body").on("dialogopen", function () {
      if (currentConfig.enableDialog && isDialogOpen() !== true) {
        checkForIframes();
      }
    });

    // document must be in readyState 'complete' before checking for iframes - $(document).ready() is not good enough!
    checkForIframes = function () {
      var docReadyCheck,
          isDocReady;

      docReadyCheck = function () {
        if (document.readyState === "complete") {
          clearInterval(isDocReady);
          includeIframes();
        }
      };

      isDocReady = setInterval(docReadyCheck, 1000); // check once a second to see if document is complete
    };

    // find and include iframes
    includeIframes = function (elementContents) {
      var iframeCount = 0;

      if (!elementContents) {
        elementContents = $(document);
      }

      elementContents.find('iframe,frame').each(function () {
        if ($(this).hasClass('jit-inspected') === false) {
          try {
            includeIframes($(this).contents()); // recursive call to include nested iframes
            // attach event code for most modern browsers
            $(this).on('load', attachEventIframe($(this))); // Browser NOT IE < 11

            // attach event code for older Internet Explorer browsers
            var domElement = $(this)[iframeCount]; // convert jquery object to dom element

            if (domElement.attachEvent) { // Older IE Browser < 11
              domElement.attachEvent('onload', attachEventIframe($(this)));
            }

            iframeCount++;
          } catch (err) {
            /* Unfortunately, if the 'includeIframes' function is manually triggered multiple times in quick succession,
             * this 'try/catch' block may not have time to complete,
             * and so it might error out,
             * and iframes that are NOT cross-site may be set to "cross-site"
             * and activity may or may not bubble to parent page.
             * Fortunately, this is a rare occurrence!
             */
            $(this).addClass('jit-inspected cross-site');
          }
        }
      });
    };

    // attach events to each iframe
    attachEventIframe = function (iframeItem) {
      // retrieve stored value as currentConfig will not include private userRuntimeConfig
      // when this function is called by public function, iframeRecheck
      var iframeContents = iframeItem.contents(), storeActivityEvents = thisStore.get('activityEvents');

      try {
        iframeContents.on(storeActivityEvents, function (event) {
          $('body').trigger(event);
        });
        iframeItem.addClass('jit-inspected'); // add "jit-inspected" class, so we don't need to check this iframe again
      } catch (err) {
        console.log('problem with attachment of activity events to this iframe');
      }
    };

    /* Build & Return the instance of the item as a plugin
     * This is your construct.
     * ############################### */
    return this.each(function () {
      if (store.enabled) {
        thisStore = store.namespace(currentConfig.idleNamespace);
        thisStore.set('idleTimerLastActivity', $.now());
        thisStore.set('idleTimerLoggedOut', false);

        activityDetector();

        if (currentConfig.sessionKeepAliveTimer) {
          startKeepSessionAlive();
        }

        startIdleTimer();
      } else {
        alert(currentConfig.errorAlertMessage);
      }
    });
  };
}(jQuery));
